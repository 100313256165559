.active{
    background-color:#f7f1f1 ;
    color: hwb(0 0% 100% / 0.989);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(10, 12, 11);
    /* background-color: rgb(63, 63, 130); */
    /* background-color: rgb(73, 67, 147); */
    /* background-color: rgb(50, 50, 102); */
    color: white;
    position: sticky;
    top: 0;
}

nav .title {
    font-family: monospace;
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: rgb(220, 230, 232);
    margin-right: auto;
}

nav ul {
    display: flex;
    font-family: monospace;
    font-size: 1.1rem;
}

nav ul li {
   list-style: none; 
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
    /* background-color:#282943 ; */
    /* background-color:#b5389e ; */
    background-color:#cecece ;
    color: #000;
}

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
}


@media (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
    
   
}

nav ul li:last-child {
    margin-right: 2.0rem; /* Adjust the value as needed */
}

nav .logo img {
    margin-left: 30px;

    /* Ensure the image is displayed as a block or inline-block element for border-radius to take effect */
    display: block;

    /* Optional: Set the width and height to ensure the image is square, which makes it perfectly round */
    width: 65px; /* or any other size */
    height: 65px; /* this should be the same as the width for a perfect circle */

    /* Optional: Add a border, if you like */
    border: px solid #b4009f; /* white border */

    /* Optional: Box shadow for a nice effect */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.color-letter-dark-blue {
    color: #1c66d5; /* Change this to your desired color */
}

.color-letter-blue {
    color: #449ea0; /* Change this to your desired color */
}

.color-letter-yellow {
    color: #ffb618; /* Change this to your desired color */
}

