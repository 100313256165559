.form-container {
    display: list-item;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-template-rows: auto 1fr; /* Row for input fields and one for the message */
    padding: 80px;
    padding-left: 250px;
    padding-right: 250px;
    font-family: monospace;
    font-size: 0.95rem;
    background-color: #fff;
    align-items: start;
    justify-content: center;
    grid-gap: 20px;
    transition: box-shadow 0.3s ease;
}

.form-container::after {
    content: "wtf"; /* Necessary for the pseudo-element to work */
    position: absolute; /* Position the pseudo-element relative to its parent */
    left: 0; /* Align to the left of the parent */
    right: 0; /* Align to the right of the parent */
    bottom: 0; /* Align to the bottom of the parent */
    height: 60px; /* Thickness of the line */
    background-color: black; /* Color of the line */
  }
  

.form-group {
    grid-column: 1; /* Take the first column */
    grid-row: 1; /* Occupies the first row */
    display: grid;
    flex-direction: column;
}

.message-group {
    grid-column: 1 / 3; /* Spans across both columns */
    grid-row: 2; /* Second row */
    display: flex;
    flex-direction: column;
}


.form-container:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}
/* This container will now hold Name, Domain, and Contact in the first column */


.form-field {
    /* display: flex;
    align-items: center;
    margin-bottom: 20px; */
    display: flex;
    flex-direction: column; /* Changes layout from row to column */
    align-items: flex-start; /* Aligns items to the start of the flex container */
    margin-bottom: 20px;
}

.form-field label {
    font-family: monospace;
    flex-basis: 30%;
    margin-right: 10px;
    color: #0c0c0c;
    margin-bottom: 5px; 
}

.form-field input,
.form-field select,
.form-field textarea {
    font-family: monospace;
    font-size: 0.9rem;
    flex-grow: 1;
    padding: 10px;
    border: 2px solid #e8e6e6;
    border-radius: 4px;
    background-color: #fff;
    transition: border 0.3s ease;
}

.form-field textarea {
    width: 650px;  
    height: 185px; 
    padding: 10px; 
    border: 2px solid #e8e6e6;
    border-radius: 4px;
    background-color: #fff;
    font-family: monospace; 
    font-size: 0.9rem;
    resize: none;
    display: block;
}

.form-field input:focus,
.form-field select:focus,
.form-field textarea:focus {
    border-color: #3096f5;
    box-shadow: 0 0 12px rgba(48, 71, 245, 0.25);
    outline: none;
}

.submit-button {
    margin-top: 230px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    background-color: #3047f5;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.submit-button:hover {
    background-color: #24cb38;
    color: #e0e0e0;
}


.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .some-page-wrapper {
    margin: 15px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
  
  .blue-column {
    height: 100px;
    width: 20px;
  }
  
  .green-column {
    height: 100px;
  }
  
  .text-top {
    font-family: monospace;
    font-size: 1.15rem;
  }

  .pink-stuff {
    font-family: monospace;
    font-size: 1.15rem;
    color: #c524cb;
  }