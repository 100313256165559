.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Reset margins and paddings for body and div elements to remove unwanted spaces */
body, div {
  margin: 0;
  padding: 0;
}

.top-text {
  background-color: #404079; /* Custom blue background */
  color: white; /* Light grey text color */
  text-align: center; /* Center the text horizontally */
  padding: 6px; /* Add some padding around the text */
  width: 100%; /* Ensure the text background spans the full width */
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin-bottom: 0; /* Ensure there's no bottom margin */
}

.background-image {
  padding: 8px; 
  height: 100vh;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat; 
  background-color: #f8f8f8; 
  /*  background-color: #0c1a27;  */
}

overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Take full height of the background container */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.vertical-block {
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align items to the start (left) */
  /* Add padding, margins, or other styles as needed */
}


.left-aligned-content {
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin-left: 300px;
  margin-top: 100px;
  font-size: 40px;
  text-align: left; /* Align text to the left */
  color: hwb(0 98% 2%);
  /* Add more styling as needed */
}


.text-border {
  display: inline; /* Make the border wrap the text closely */
  padding: 5px; /* Small padding around the text inside the border */
  /* Add background-color if needed */
}

.left-aligned-content-progress {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 40px;
  margin-top: 20px;
  text-align: left; /* Align text to the left */
  color: hwb(0 98% 2%);

  /* Add more styling as needed */
}

.header {
  background-color: #a4c991;
  padding: 10px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: grid; /* Correctly using Grid */
  justify-content: start; /* Align grid items to the start (left) */
  align-items: start; /* Align grid items to the start (top) */
  height: 100vh; /* Use the full height of the viewport */
  padding: 0 80px; /* Padding on the right and left */
  margin: 5ch;
}

.left-text {
  font-weight: 200;
  font-family: monospace;
  font-size: 1.15rem; 
  text-align: left; /* Align text to the left */
  margin-top: 90px;
  margin-left: 50px;
  line-height: 25px;
  /* Removed flex properties since they may not be necessary for simple text alignment */
}

.centered-text {
  /* Additional styles for the text */
  font-size: 20px; /* Example font size */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;/* Example font family */
}

.right-image img {
  width: 200px; /* Or any size you prefer */
  height: auto; /* Maintains the aspect ratio */
  /* Add more styling as needed */
}

.container-left-part {
  display: grid;
  grid-template-columns: 1.5fr 0.3fr 1fr; 
  justify-items: start; /* Align grid items to the start (left) of their respective column */
  align-items: start; /* Align grid items to the start (top) */
  height: 80vh; /* Use the full height of the viewport */
  padding: 0 80px; /* Padding on the right and left */
  margin: 0 auto; /* Center the element horizontally */
}

.container-left-part::after {
  content: "wtf"; /* Necessary for the pseudo-element to work */
  position: absolute; /* Position the pseudo-element relative to its parent */
  left: 0; /* Align to the left of the parent */
  right: 0; /* Align to the right of the parent */
  bottom: 0; /* Align to the bottom of the parent */
  height: 60px; /* Thickness of the line */
  background-color: black; /* Color of the line */
}

.middle-part-image img {
  margin-top: 180px;
  margin-right: -300px;
  justify-self: center; /* Align the image container to the center of its grid column */
  width: 12.0rem; /* Adjust the width as necessary */
  height: 4.0rem; /* Adjust the height as necessary, ensure it's the same as the width for a perfect circle */
  object-fit: scale-down; /* Ensures the image covers the container without stretching */
}

.right-part-image img {
  margin-top: 100px;
  margin-right: -500px;
  justify-self: end; /* Align the image container to the end of its grid column */
  border-radius: 50%; /* This creates the rounded corners */
  width: 25.0rem; /* Adjust the width as necessary */
  height: 25.0rem; /* Adjust the height as necessary, ensure it's the same as the width for a perfect circle */
  object-fit: cover;
}

.container-with-effect {
  display: grid;
  padding: 0 80px; /* Padding on the right and left */
  margin: 0 auto; /* Center the element horizontally */
  /* place-items: center; */
}

.container-with-effect::after {
  content: "wtf"; /* Necessary for the pseudo-element to work */
  position: absolute; /* Position the pseudo-element relative to its parent */
  left: 0; /* Align to the left of the parent */
  right: 0; /* Align to the right of the parent */
  bottom: 0; /* Align to the bottom of the parent */
  height: 60px; /* Thickness of the line */
  background-color: black; /* Color of the line */
}

.text-content {
  font-weight: 200;
  font-family: monospace;
  font-size: 1.15rem; 
  margin-top: 140px;
  margin-left: 50px;
  place-items: center;
}

